<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title class="text-h5 text-center">
        <v-row no-gutters>
          <v-col>{{message}}</v-col>
        </v-row>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="closeDelete"
        >
          fechar
        </v-btn>
        <v-btn
          color="error"
          :loading="loading"
          @click="deleteItemConfirm"
        >
          Sim
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: { type: Boolean },
    message:{type:String,default:'Deseja realmente excluir ?'}
  },
  data: () => ({
    loading: false,
    dialog: false,
  }),
  watch: {
    value(val) {
      this.dialog = val
      this.loading = false
    },
    dialog(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    closeDelete() {
      this.dialog = false
    },
    deleteItemConfirm() {
      this.loading = true
      this.$emit('delete-yes')
    },
  },
}
</script>
