<template>
<div>
    <v-row no-gutters :class="{title_with_margin:margin_bottom,title:!margin_bottom}">
        <v-col cols="2">
            <v-btn v-if="btn_back" class="mt-1 ml-1" icon small outlined @click="$emit('back')">
                <v-icon> {{ icons.mdiChevronLeft }} </v-icon>
            </v-btn>
        </v-col>
        <v-col class="text-center "><span class="text">{{title.toUpperCase()}}</span></v-col>
        <v-col cols="2" class="text-right">
            <v-btn v-if="btn_close" class="mt-1 mr-1" small icon @click="$emit('close')">
                <v-icon> {{ icons.mdiWindowClose }} </v-icon>
            </v-btn>
        </v-col>
    </v-row>
</div>
</template>

<script>
import {
    mdiWindowClose,
    mdiChevronLeft
} from '@mdi/js'

export default {
    // eventos emitidos
    // back : botao voltar
    // close : botao fechar

    props: {
        title: { // titulo da barra
            type: String,
            default: 'TITLE'
        },
        btn_back: {
            type: Boolean,
            default: false
        },
        btn_close: {
            type: Boolean,
            default: true
        },
        margin_bottom: {
            type: Boolean,
            default: true
        }

    },
    components: {},
    data() {
        return {
            dialog: false,
            tabs: null,
            icons: {
                mdiChevronLeft,
                mdiWindowClose
            }

        }
    },
    methods: {
        back() {
            this.setStage('new_sale')
        },

    }
}
</script>

<style scoped>
.title_with_margin {
    background: #16b1ff;
    width: 100%;
    height: 38px;
    box-sizing: border-box;
    padding: 3px;
    margin-bottom: 20px;
    text-align: center;
}

.title {
    background: #16b1ff;
    width: 100%;
    height: 38px;
    box-sizing: border-box;
    padding: 3px;
    text-align: center;
}

.text {
    color: white;
    font-size: 25px;
}

.bar_top {
    height: 5px;
    background: #9A62FD;
}
.text-40{
  font-size: 40px;
}
</style>
