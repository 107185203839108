<template>
    <v-select :error-messages="errors" outlined v-model="tariff"  :loading="loading" :disabled="loading" label="Tarifas" :items="tariffs" item-text="description" item-value="id" ></v-select>
</template>

<script>
import axios from '@/plugins/axios'
import { mapGetters } from 'vuex'
export default {

    components: {},
    props: {
        value: {},
        errors:{},
    },

    data: () => ({
        loading: false,
        tariff: '',
        tariffs:[],
    }),

    watch: {
        value(val) {
            this.tariff = val

        },
        tariff(){


          //proucura o objeto correspondente ao id tarriff
          const tariffObject = this.tariffs.find(item =>{
            return item.id == this.tariff
          })

          this.$emit('object',tariffObject)

          this.$emit('input',this.tariff)
          this.$emit('change',this.tariff)
        }

    },

    created() {
        this.tariffs = this.getTariffs
        this.tariff = this.value
        // this.loading = true
        // axios
        //     .get('helper/get_room_categorys')
        //     .then(response => {
        //       this.roomCategorys = response.data
        //     })
        //     .finally(() => {
        //         this.loading = false
        //     })
    },
    computed: {
       ...mapGetters('select',['getTariffs'])
    },

    methods: {},
}
</script>
